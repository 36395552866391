import { createRouter, createWebHistory } from 'vue-router'
//import HomeView from '../views/shmuzy/HomeView.vue'
const isYeshiva = process.env.VUE_APP_BUILD_TARGET_NAME === 'yeshiva'
const routes = [
  {
    path: '/',
    name: 'home',
    component: () => {
      if (isYeshiva) {
        return import('../views/yeshiva/HomeView.vue')
      } else {
        return import('../views/shmuzy/HomeView.vue')
      }
    },
    beforeEnter: () => {
      let token = JSON.parse(localStorage.getItem('token'));
      if( token ) return { name: 'designed-chats-home' }

      return true
    },
  },
  {
    path: '/login',
    name: 'login',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/LoginView.vue')
  },
  {
    path: '/terms',
    name: 'terms',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => {
      if (isYeshiva) {
        return import('../views/yeshiva/TermsView.vue')
      } else {
        return import('../views/shmuzy/TermsView.vue')
      }
    }
  },
  {
    path: '/chats',
    name: 'chats',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/ChatsView.vue')
  },

  // Designed Screens
  // Designed Screens
  // Designed Screens
  // Designed Screens
  // Designed Screens
  // Designed Screens
  // Designed Screens

  {
    path: '/designed-chats-home',
    name: 'designed-chats-home',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/DesignedScreens/DesignedChatsHome.vue')
  },
  {
    path: '/designed-chats-new',
    name: 'designed-chats-new',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/DesignedScreens/DesignedChatsNew.vue')
  },
  {
    path: '/designed-chats-new-group',
    name: 'designed-chats-new-group',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/DesignedScreens/DesignedChatsNewGroup.vue')
  },
  {
    path: '/designed-chats-new-group-add',
    name: 'designed-chats-new-group-add',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/DesignedScreens/DesignedChatsNewGroupAdd.vue')
  },
  {
    path: '/new-forum',
    name: 'new-forum',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/ForumView.vue')
  },

  {
    path: '/register',
    name: 'register',
    component: () => import('../views/RegisterView.vue')
  },

  {
    path: '/camera',
    name: 'camera',
    component: () => import('../components/shared/CameraComponent.vue')
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach(async (to) => {
  let token = JSON.parse(localStorage.getItem('token'));
  
  if ( !token && ( to.name !== 'login' && to.name !== 'home' && to.name !== 'terms'  ) ) {
    return { name: 'login' }
  }
})

export default router
