<template>
  <router-view/>
</template>


<style>
@font-face {
  font-family: 'SF UI Text';
  font-style: normal;
  font-weight: 300;
  src: local('SF UI Text Light'), url('./assets/fonts/SF UI Text/SFUIText-Light.woff') format('woff');
}


@font-face {
  font-family: 'SF UI Text';
  font-style: italic;
  font-weight: 300;
  src: local('SF UI Text Light Italic'), url('./assets/fonts/SF UI Text/SFUIText-LightItalic.woff') format('woff');
}

@font-face {
  font-family: 'SF UI Text';
  font-style: normal;
  font-weight: 400;
  src: local('SF UI Text Regular'), url('./assets/fonts/SF UI Text/SFUIText-Regular.woff') format('woff');
}


@font-face {
  font-family: 'SF UI Text';
  font-style: italic;
  font-weight: 400;
  src: local('SF UI Text Italic'), url('./assets/fonts/SF UI Text/SFUIText-RegularItalic.woff') format('woff');
}


@font-face {
  font-family: 'SF UI Text';
  font-style: normal;
  font-weight: 500;
  src: local('SF UI Text Medium'), url('./assets/fonts/SF UI Text/SFUIText-Medium.woff') format('woff');
}


@font-face {
  font-family: 'SF UI Text';
  font-style: italic;
  font-weight: 500;
  src: local('SF UI Text Medium Italic'), url('./assets/fonts/SF UI Text/SFUIText-MediumItalic.woff') format('woff');
}


@font-face {
  font-family: 'SF UI Text';
  font-style: normal;
  font-weight: 600;
  src: local('SF UI Text Semibold'), url('./assets/fonts/SF UI Text/SFUIText-Semibold.woff') format('woff');
}


@font-face {
  font-family: 'SF UI Text';
  font-style: italic;
  font-weight: 600;
  src: local('SF UI Text Semibold Italic'), url('./assets/fonts/SF UI Text/SFUIText-SemiboldItalic.woff') format('woff');
}


@font-face {
  font-family: 'SF UI Text';
  font-style: normal;
  font-weight: 700;
  src: local('SF UI Text Bold'), url('./assets/fonts/SF UI Text/SFUIText-Bold.woff') format('woff');
}


@font-face {
  font-family: 'SF UI Text';
  font-style: italic;
  font-weight: 700;
  src: local('SF UI Text Bold Italic'), url('./assets/fonts/SF UI Text/SFUIText-BoldItalic.woff') format('woff');
}


@font-face {
  font-family: 'SF UI Text';
  font-style: normal;
  font-weight: 800;
  src: local('SF UI Text Heavy'), url('./assets/fonts/SF UI Text/SFUIText-Heavy.woff') format('woff');
}


@font-face {
  font-family: 'SF UI Text';
  font-style: italic;
  font-weight: 800;
  src: local('SF UI Text Heavy Italic'), url('./assets/fonts/SF UI Text/SFUIText-HeavyItalic.woff') format('woff');
}

@font-face {
  font-family: 'Averta';
  font-style: normal;
  font-weight: 400;
  src: local('Averta-Regular'), url('./assets/fonts/Averta/Averta-Regular.otf') format('opentype');
}

:root {
  --body-color: #000000;
  --body-color-rgb: 0, 0, 0;

  --body-bg: #ffffff;
  --body-bg-rgb: 255, 255, 255;

  --body-font-family: "SF UI Text";
  --body-font-size: 18px;
  --body-font-weight: 400;
  --body-line-height: 21.48px;

  --body-secondary-color: #747474;

  --body-secondary-font-size: 15px;
  --body-secondary-line-height: 17.9px;

  --border-color: #D3D3D3;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

body {
  margin: 0;
  font-family: var(--body-font-family);
  font-size: var(--body-font-size);
  font-weight: 400;
  line-height: var(--body-line-height);
  color: var(--body-color);
  text-align: left;
  background-color: var(--body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 700;
  margin-top: 0;
  margin-bottom: 0;
}

h3, .h3 {
  font-size: 24px;
  padding-bottom: 25px;
  line-height: 28.64px;
}

h4, .h4 {
  font-size: 18px;
  line-height: 21.48px;
  font-weight: 700;
}

.btn-icon {
  background-color: transparent;
  border: 0;
  cursor: pointer;
  padding: 0;
  outline: none;
}

a {
  cursor: pointer;
}

button {
  cursor: pointer;
  margin: 0;
  padding: 0;
  font-size: var(--body-font-size);
  font-weight: 300;
}

input {
  border: 0;
  font-family: var(--body-font-family);
  font-size: var(--body-font-size);
  line-height: 21.48px;
  outline: none;
  padding: 0;
}

input.input-min-type {
  height: auto;
  width: 100%;
  padding-bottom: 19px;
  border-bottom: 1px var(--border-color) solid;
  padding-top: 19px;
  margin-top: 15px;
}

input.input-ios-type {
  border-radius: 33px;
  height: 36px;
  width: 100%;
  padding: 7px 0 8px 48px;
  box-shadow: 0px 2px 10px 0px #91919173;
  transition: .15s ease;
}

input::placeholder {
  color: var(--body-color);
  opacity: 1;
}

input:-ms-input-placeholder {
 color: var(--body-color);
}

input::-ms-input-placeholder {
 color: var(--body-color);
}

.btn-primary {
  color: #ffffff;
  background: linear-gradient(92.52deg, #59CF56 7.92%, #97F594 94.91%);
  padding: 15px;
  padding-bottom: 14px;
  font-weight: 500;
  border-radius: 43px;
  border: 0;
}

.sidebar {
  max-width: 429px;
  height: 100vh;
  width: 100%;
  background-color: #ffffff;
  position: relative;
}

.sidebar-top {
  width: 100%;
  display: flex;
  border-right: 1px solid var(--border-color);
  position: relative;
  background-color: #ffffff;
  z-index: 1;
}

.sidebar-top h1.h4 {
  width: 100%;
  text-align: center;
  padding-top: 33px;
  padding-left: 60.73px;
  padding-right: 60.73px;
}

.sidebar-top .btn-back {
  top: 29.72px;
  left: 23.73px;
  position: absolute;
  display: flex;
  z-index: 1;
}

.sidebar-top .btn-next {
  top: 34px;
  right: 34px;
  position: absolute;
  display: flex;
  z-index: 1;
  text-decoration: none;
  color: var(--body-color);
}

.sidebar-top-search {
  position: relative;
  width: 100%;
  padding: 28px 0 0 0;
  display: flex;
  justify-content: center;
}

.sidebar-top-search input.input-ios-type {
  box-shadow: none;
  width: 97px;
  padding-left: 38px;
  padding-bottom: 7px;
  padding-top: 0;
  padding-bottom: 0;
  position: relative;
  z-index: 1;
  background-color: transparent;
  height: 26px;
}

.sidebar-top-search input.input-ios-type:focus,
.sidebar-top-search input.input-ios-type:not(:placeholder-shown) {
  width: 100%;
  padding-left: 98.73px;
}

.sidebar-top-search img {
  position: absolute;
  top: 28px;
  left: calc(50% - 48px);
  z-index: 0;
  transition: .15s ease;
}

.sidebar-top-search input.input-ios-type:focus + img,
.sidebar-top-search input.input-ios-type:not(:placeholder-shown) + img {
  left: 60.73px;
}



</style>
